
@import url('https://fonts.googleapis.com/css2?family=Oxygen&family=Sora:wght@100;200;400;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@100&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Sora', sans-serif;
  }
}